.contact-container {
    position: relative;
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: rgba(51, 54, 57, 1);
    z-index: 0;
    box-sizing: border-box;
}

.contact-info {
    margin-top: 75px;
    color: rgba(223, 234, 236, 1);
}

.contact-header {
    position: relative;
}

.contact-tagline {
    position: absolute;
    font-size: 32px;
    width: 50%;
    left: 32px;
    text-align: left;
}

.contact-subTagline {
    position: absolute;
    font-size: 24px;
    width: 40%;
    top: 25px;
    right: 32px;
    text-align: right;
}

.contact-title {
    position: absolute;
    font-size: 16px;
    margin: 8px 48px;
    top: 175px;
    left: 0;
    width: 50%;
}

.contact-subtitle {
    position: absolute;
    font-size: 32px;
    margin: 8px 16px;
    top: 225px;
    right: 0;
}

.contact-form {
    margin-top: 200px;
}

/* .cf1,
.cf2 {
    display: flex;
    flex-direction: column;
} */

.fn-container,
.email-container,
.phone-container,
.company-container,
.website-container,
.budget-container,
.source-container,
.details-container {
    position: relative;
    display: block;
    margin: 0;
}

.labels {
    position: absolute;
    font-size: 12px;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
    color: rgba(223, 234, 236, 0.7);
    pointer-events: none;
}

.contact-input {
    width: 100%;
    padding: 16px 32px;
    font-size: 12px;
    border-radius: 50px;
    background: rgba(51, 54, 57, 0.8);
    border: 2px solid rgba(223, 234, 236, 0.5);
    color: rgba(223, 234, 236, 1);
    outline: none;
    transition: border-color 0.3s, background-color 0.3s;
    overflow: hidden;
}

.contact-input:focus,
.contact-input:placeholder-shown+.labels,
.contact-input:not(:placeholder-shown)+.labels {
    background: rgba(51, 54, 57, 1);
    border-color: #DAE0E5;
}

.contact-input:focus+.labels,
.contact-input:placeholder-shown+.labels,
.contact-input:not(:placeholder-shown)+.labels {
    top: 0;
    left: 32px;
    color: #DAE0E5;
    padding: 0 8px;
    border-radius: 50px;
}

.contact-input:focus::placeholder {
    color: transparent;
}

.contact-input.error {
    border: 1px solid rgba(192, 173, 109, 1);
}

.required {
    color: rgba(192, 173, 109, 1);
}

.contact-button {
    display: block;
    font-family: "Chivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    padding: 4px 48px;
    color: rgba(223, 234, 236, 1);
    border: 1px solid rgba(223, 234, 236, 1);
    border-radius: 50px;
    background: transparent;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    transition: padding-right 0.5s ease;
}

/* .contact-button {
    width: auto;
    margin: 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
} */

.contact-button:hover,
.contact-button:focus {
    background: rgba(223, 234, 236, 0.1);
    transform: translateY(-2px);
}

@media screen and (max-width: 575px) and (orientation: landscape) {}

/* Small Devices (Phones >= 576px) */

@media screen and (min-width: 576px) and (orientation: portrait) {}

@media screen and (min-width: 576px) and (orientation: landscape) {}

/* Medium Devices (Tablets >=768px) */

@media screen and (min-width: 768px) and (orientation: portrait) {}

@media screen and (min-width: 768px) and (orientation: landscape) {}

/* Large Devices (Desktops >=992px) */

@media screen and (min-width: 992px) and (orientation: portrait) {}

@media screen and (min-width: 992px) and (orientation: landscape) {}

/* Extra Large Devices (Large Desktops >=1200px) */

@media screen and (min-width: 1200px) and (orientation: portrait) {}

@media screen and (min-width: 1200px) and (orientation: landscape) {}

/* Extra Large Devices (Large Desktops >=1400px) */

@media screen and (min-width: 1400px) and (orientation: portrait) {}

@media screen and (min-width: 1400px) and (orientation: landscape) {}