body {
  margin: 0;
  font-family: "Playfair Display", "Chivo", serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

html {
  scroll-behavior: smooth;
}

@media screen and (max-width: 575px) and (orientation: landscape) {

}

/* Small Devices (Phones >= 576px) */

@media screen and (min-width: 576px) and (orientation: portrait) {

}

@media screen and (min-width: 576px) and (orientation: landscape) {

}

/* Medium Devices (Tablets >=768px) */

@media screen and (min-width: 768px) and (orientation: portrait) {

}

@media screen and (min-width: 768px) and (orientation: landscape) {

}

/* Large Devices (Desktops >=992px) */

@media screen and (min-width: 992px) and (orientation: portrait) {

}

@media screen and (min-width: 992px) and (orientation: landscape) {

}

/* Extra Large Devices (Large Desktops >=1200px) */

@media screen and (min-width: 1200px) and (orientation: portrait) {

}

@media screen and (min-width: 1200px) and (orientation: landscape) {

}

/* Extra Large Devices (Large Desktops >=1400px) */

@media screen and (min-width: 1400px) and (orientation: portrait) {

}

@media screen and (min-width: 1400px) and (orientation: landscape) {

}