.App {
  display: flex;
  flex-direction: column;
}

/* In your CSS file */
.desktop-note {
  display: none; /* Hide by default */
  position: fixed; /* or 'absolute', depending on your layout */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75); /* semi-transparent black background */
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  font-size: 32px;
  z-index: 1000; /* Ensure it's above other content */
}

/* Mobile Burger */
.toggle-button {
  background-color: rgb(214, 64, 172, 0.5);
  border-radius: 25px;
  border: whitesmoke;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 10000;
  transition: all 0.3s ease-in-out;
}

.toggle-button .bar1,
.toggle-button .bar2,
.toggle-button .bar3 {
  align-self: center;
  width: 1rem;
  height: 0.15rem;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.toggle-button.toggled .bar1 {
  transform: rotate(-45deg) translate(-6px, 6px);
}

.toggle-button.toggled .bar2 {
  opacity: 0;
}

.toggle-button.toggled .bar3 {
  transform: rotate(45deg) translate(-6px, -6px);
}

/* Scroll to top Button */
.scrollToTop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgb(214, 64, 172, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  z-index: 10000;
}

.scrollToTop:hover {
  background-color: rgb(214, 64, 172);
}


@media screen and (max-width: 575px) and (orientation: landscape) {

}

/* Small Devices (Phones >= 576px) */

@media screen and (min-width: 576px) and (orientation: portrait) {

}

@media screen and (min-width: 576px) and (orientation: landscape) {

}

/* Medium Devices (Tablets >=768px) */

@media screen and (min-width: 768px) and (orientation: portrait) {

}

@media screen and (min-width: 768px) and (orientation: landscape) {

}

/* Large Devices (Desktops >=992px) */

@media screen and (min-width: 992px) and (orientation: portrait) {
  .desktop-note {
    display: flex;
  }
}

@media screen and (min-width: 992px) and (orientation: landscape) {
  .desktop-note {
    display: flex;
  }
}

/* Extra Large Devices (Large Desktops >=1200px) */

@media screen and (min-width: 1200px) and (orientation: portrait) {

}

@media screen and (min-width: 1200px) and (orientation: landscape) {

}

/* Extra Large Devices (Large Desktops >=1400px) */

@media screen and (min-width: 1400px) and (orientation: portrait) {

}

@media screen and (min-width: 1400px) and (orientation: landscape) {

}

