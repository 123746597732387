.nav-container {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 16px 0;
    margin: 0 16px;
    backdrop-filter: blur(2px);
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.scroll-base {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: rgba(223, 234, 236, 0.3);
    transition: background-color 0.25s ease-out;
}

.scroll-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: rgba(223, 234, 236, 1);
    transition: width 0.25s ease-out;
}

.logo {
    display: inline-block;
    color: rgba(223, 234, 236, 1);
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.logo-greek {
    position: absolute;
    top: 0;
    left: 0;
    color: rgba(192, 173, 109, 1);
    letter-spacing: 0;
}

.section-links {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0;
    font-size: 16px;
}

.section-links li {
    display: inline-block;
}

.links {
    border: none;
    background: none;
    color: rgba(223, 234, 236, 1);
    font-weight: 600;
}

.links:hover {
    color: white;
}

@media screen and (max-width: 575px) and (orientation: landscape) {}

/* Small Devices (Phones >= 576px) */

@media screen and (min-width: 576px) and (orientation: portrait) {}

@media screen and (min-width: 576px) and (orientation: landscape) {}

/* Medium Devices (Tablets >=768px) */

@media screen and (min-width: 768px) and (orientation: portrait) {}

@media screen and (min-width: 768px) and (orientation: landscape) {}

/* Large Devices (Desktops >=992px) */

@media screen and (min-width: 992px) and (orientation: portrait) {}

@media screen and (min-width: 992px) and (orientation: landscape) {}

/* Extra Large Devices (Large Desktops >=1200px) */

@media screen and (min-width: 1200px) and (orientation: portrait) {}

@media screen and (min-width: 1200px) and (orientation: landscape) {}

/* Extra Large Devices (Large Desktops >=1400px) */

@media screen and (min-width: 1400px) and (orientation: portrait) {}

@media screen and (min-width: 1400px) and (orientation: landscape) {}