/* iphone SE - Styles */
.hero-container {
    position: relative;
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background: white;
    z-index: 0;
}

.hero-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/public/Rubens_-_Vulcano_forjando_los_rayos_de_Jupiter.jpg');
    background-size: cover;
    opacity: 80%;
    mix-blend-mode: luminosity;
    z-index: -2;
}

.hero-container::after {
    content: "";
    height: 100px;
    width: 900px;
    position: absolute;
    top: 15%;
    left: -48%;
    transform: rotate(-44.24deg);
    background: rgba(49, 54, 54, 0.5);
    transform-origin: center;
    z-index: -1;
}

.hero-section {
    position: relative;
    padding-top: 220px;
    z-index: 1;
}

.media-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 20;
}

.media-icons {
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 25px 15px;
    left: 0;
    top: 50px;
    z-index: 10;
}

.media-icons li {
    padding: 2.5px 0;
}

.media {
    font-size: 32px;
    color: rgba(223, 234, 236, 1);
}

.date-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100px;
    top: 75px;
    right: 16px;
    color: rgba(223, 234, 236, 1);
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    font-style: normal;
    z-index: 10;
}

.month,
.schedule {
    font-size: 12px;
    line-height: 10px;
    font-weight: 800;
}

.month {
    text-align: center;
    margin-bottom: 8px;
}

.day {
    font-size: 72px;
    text-align: center;
    line-height: 16px;
    color: rgba(192, 173, 109, 1);
    margin-right: 32px;
}

.schedule {
    text-align: left;
    line-height: 32px;
    margin-top: 20px;
}

.fa-calendar-check {
    font-size: 12px;
    color: rgba(223, 234, 236, 1);
}

.tag-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;
    color: rgba(223, 234, 236, 1);
}

.tag,
.span-tag {
    font-family: "Playfair Display", serif;
    padding-left: 16px;
    margin-bottom: 0;
    line-height: 2.25;
}

.inline-tag {
    display: flex;
    align-items: flex-end;
}

.tag {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: -2px;
    color: rgba(192, 173, 109, 1);
}

.span-tag {
    font-size: 24px;
    font-weight: 600;
    line-height: 1;
    z-index: 10;
}

.and-tag {
    padding-left: 8px;
}

.summary-container {
    font-family: "Chivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    backdrop-filter: blur(2px);
    margin: 32px 16px;
    font-size: 16px;
    color: rgba(223, 234, 236, 1);
    line-height: 1.25;
}

.summary1,
.summary2 {
    margin-bottom: 0;
}

.summary1 {
    text-align: right;
}

.contact-btn {
    position: absolute;
    bottom: -8px;
    right: 60px;
    font-family: "Chivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    padding: 4px 16px;
    color: rgba(223, 234, 236, 1);
    border: 1px solid rgba(223, 234, 236, 1);
    border-radius: 50px;
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: padding-right 0.5s ease;
}

.contact-btn:hover {
    padding-right: 30px;
    overflow: hidden;
    transition: padding-right 0.5s ease;
}

.contact-btn .fa-arrow-right {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease, right 0.5s ease;
    right: -10px;
    overflow: hidden;
}

.contact-btn:hover .fa-arrow-right {
    opacity: 1;
    right: 10px;
    overflow: hidden;
}

.hero-section,
.project-info {
    font-size: 24px;
    color: gray;
}

.project-info {
    font-family: "New York";
    text-decoration: none;
    margin: 0;
}

@media screen and (min-width: 430px) and (orientation: portrait) {

    .hero-container::after {
        top: 25%;
        left: -50%;
        transform: rotate(-44.24deg);
    }

    .hero-section {
        padding-top: 275px;
    }

    .media-icons {
        position: absolute;
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 25px 15px;
        left: 0;
        top: 100px;
        z-index: 10;
    }

    .date-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100px;
        top: 118px;
        right: 16px;
        color: rgba(223, 234, 236, 1);
        font-family: "Playfair Display", serif;
        font-optical-sizing: auto;
        font-style: normal;
        z-index: 10;
    }


    .media {
        font-size: 32px;
    }

    .month,
    .schedule {
        font-size: 16px;
    }

    .day {
        font-size: 72px;
        line-height: 48px;
    }

    .schedule {
        margin-top: 8px;
        line-height: 0;
        margin-left: -16px;
    }

    .tag {
        font-size: 48px;
    }

    .span-tag {
        font-size: 32px;
    }

    .summary-container {
        margin-top: 50px;
        font-size: 20px;
    }

    .contact-btn {
        bottom: -24px;
        right: 60px;
        font-size: 20px;
        padding: 4px 16px;
    }
}

@media screen and (max-width: 575px) and (orientation: landscape) {}

/* Small Devices (Phones >= 576px) */

@media screen and (min-width: 576px) and (orientation: portrait) {}

@media screen and (min-width: 576px) and (orientation: landscape) {

    .hero-container::after {
        display: none;
    }

    .hero-section {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding-top: 140px;
        z-index: 1;
    }

    .media-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 20;
    }

    .media-icons {
        position: absolute;
        display: flex;
        flex-direction: row;
        list-style: none;
        margin: 0;
        padding: 25px 15px;
        left: 0;
        top: 50px;
        z-index: 10;
    }

    .media-icons li {
        padding: 5px;
    }

    .media {
        font-size: 32px;
        color: rgba(223, 234, 236, 1);
    }

    .date-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100px;
        top: 75px;
        right: 16px;
        color: rgba(223, 234, 236, 1);
        font-family: "Playfair Display", serif;
        font-optical-sizing: auto;
        font-style: normal;
        z-index: 10;
    }

    .month,
    .schedule {
        font-size: 12px;
        line-height: 10px;
        font-weight: 800;
    }

    .month {
        text-align: center;
        margin-bottom: 8px;
    }

    .day {
        font-size: 72px;
        text-align: center;
        line-height: 16px;
        color: rgba(192, 173, 109, 1);
        margin-right: 32px;
    }

    .schedule {
        text-align: left;
        line-height: 16px;
        margin-top: 20px;
    }

    .fa-calendar-check {
        font-size: 12px;
        color: rgba(223, 234, 236, 1);
    }

    .summary-container {
        font-family: "Chivo", sans-serif;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
        backdrop-filter: blur(2px);
        margin: 48px 16px;
        font-size: 16px;
        color: rgba(223, 234, 236, 1);
        line-height: 1.1;
        width: 50%;
    }

    .summary1,
    .summary2 {
        margin-bottom: 0;
    }

    .summary1 {
        text-align: right;
    }

    .contact-btn {
        position: absolute;
        bottom: 0;
        right: 16px;
        font-family: "Chivo", sans-serif;
        font-optical-sizing: auto;
        font-weight: 600;
        font-style: normal;
        font-size: 16px;
        padding: 4px 16px;
        color: rgba(223, 234, 236, 1);
        border: 1px solid rgba(223, 234, 236, 1);
        border-radius: 50px;
        background: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: padding-right 0.5s ease;
    }

}

/* Medium Devices (Tablets >=768px) */

@media screen and (min-width: 768px) and (orientation: portrait) {}

@media screen and (min-width: 768px) and (orientation: landscape) {}

/* Large Devices (Desktops >=992px) */

@media screen and (min-width: 992px) and (orientation: portrait) {}

@media screen and (min-width: 992px) and (orientation: landscape) {}

/* Extra Large Devices (Large Desktops >=1200px) */

@media screen and (min-width: 1200px) and (orientation: portrait) {}

@media screen and (min-width: 1200px) and (orientation: landscape) {}

/* Extra Large Devices (Large Desktops >=1400px) */

@media screen and (min-width: 1400px) and (orientation: portrait) {}

@media screen and (min-width: 1400px) and (orientation: landscape) {}