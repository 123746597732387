/* iphone SE - Styles */
.build-container {
    position: relative;
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background: rgba(51, 54, 57, 1);
    z-index: 0;
}

.build-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/public/IMG_89F41A3E60D6-1.jpeg');
    background-size: cover;
    opacity: 10%;
    mix-blend-mode: luminosity;
    z-index: -2;
}

.build-title {
    font-size: 24px;
    color: rgba(223, 234, 236, 1);
    margin-top: 75px;
    padding: 0 16px;
    text-align: left;
    width: 100%;
}

.projects {
    color: rgba(223, 234, 236, 1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.project-card {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 10px;
}

/* .project-card::after {
    content: "";
    height: auto;
    width: 529px;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: rgba(1, 54, 57, 17);
    transform-origin: center;
    mix-blend-mode: multiply;
    z-index: 1;
} */

.project-card:hover {
    /* background: rgba(223, 234, 236, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); */
    transform: scale(1.05);
}

/* .project-card:nth-child(odd) {
    flex-direction: row-reverse;
    text-align: right;
} */

.project-title {
    display: none;
    font-size: 24px;
    font-weight: bold;
}

.project-image {
    max-width: 100%;
    height: auto;
}
@media screen and (max-width: 575px) and (orientation: landscape) {

}

/* Small Devices (Phones >= 576px) */

@media screen and (min-width: 576px) and (orientation: portrait) {

}

@media screen and (min-width: 576px) and (orientation: landscape) {

}

/* Medium Devices (Tablets >=768px) */

@media screen and (min-width: 768px) and (orientation: portrait) {

}

@media screen and (min-width: 768px) and (orientation: landscape) {

}

/* Large Devices (Desktops >=992px) */

@media screen and (min-width: 992px) and (orientation: portrait) {

}

@media screen and (min-width: 992px) and (orientation: landscape) {

}

/* Extra Large Devices (Large Desktops >=1200px) */

@media screen and (min-width: 1200px) and (orientation: portrait) {

}

@media screen and (min-width: 1200px) and (orientation: landscape) {

}

/* Extra Large Devices (Large Desktops >=1400px) */

@media screen and (min-width: 1400px) and (orientation: portrait) {

}

@media screen and (min-width: 1400px) and (orientation: landscape) {

}