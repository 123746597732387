.bio-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: rgba(51,54,57,1);
    font-family: "Chivo", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;  
}

.bio-title {
    margin-top: 75px;
    font-size: 48px;
    color: rgba(192, 173, 109, 1);
    text-align: right;
    margin-right: 16px;
    margin-bottom: 0;
}

.bio-title-greek {
    color: rgba(223, 234, 236, 1);
}

.bio-subtitle {
    text-align: right;
    font-size: 16px;
    color: rgba(223, 234, 236, 1);
    margin: 0;
    padding: 16px;
}

.bio-subtitle-greek {
    color: rgba(192, 173, 109, 1);
}

.bio-description-container {
    color: rgba(223, 234, 236, 1);
}

.bio-item {
    padding: 0 16px;
    display: block;
    text-align: left;
}

.bi-section {
    display: block;
    text-align: left;
    padding: 0 16px;
}

@media screen and (max-width: 575px) and (orientation: landscape) {

}

/* Small Devices (Phones >= 576px) */

@media screen and (min-width: 576px) and (orientation: portrait) {

}

@media screen and (min-width: 576px) and (orientation: landscape) {

}

/* Medium Devices (Tablets >=768px) */

@media screen and (min-width: 768px) and (orientation: portrait) {

}

@media screen and (min-width: 768px) and (orientation: landscape) {

}

/* Large Devices (Desktops >=992px) */

@media screen and (min-width: 992px) and (orientation: portrait) {

}

@media screen and (min-width: 992px) and (orientation: landscape) {

}

/* Extra Large Devices (Large Desktops >=1200px) */

@media screen and (min-width: 1200px) and (orientation: portrait) {

}

@media screen and (min-width: 1200px) and (orientation: landscape) {

}

/* Extra Large Devices (Large Desktops >=1400px) */

@media screen and (min-width: 1400px) and (orientation: portrait) {

}

@media screen and (min-width: 1400px) and (orientation: landscape) {

}